
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class UserGuide extends Vue {
  loading = false

  mounted() {
    console.debug(this.activeUser)
  }

  get filteredDocuments() {
    return this.manuals.filter((x: any) =>
      x.roles.every((m: string) => this.activeUser.roles.findIndex((x: any) => x.name == m) > -1)
    )
  }
  // computed // ***
  get activeUser() {
    return this.$auth.activeUser
  }
  get headers() {
    return [
      { text: 'Manual', value: 'description' },
      { text: 'Link', value: 'link' },
    ]
  }
  get manuals() {
    return [
      {
        id: 1,
        description: 'Supplier portal guide',
        link: '/documents/Supplier Engagement Portal Guide V5.1.pdf',
        roles: ['SuperAdmin', 'CmacAdmin', 'SupplierUser', 'LargeVehicleUser'],
      },
      {
        id: 2,
        description: 'Invoice upload guide',
        link: '/documents/SEP Invoice Uploads Guide V1.0.pdf',
        roles: ['SuperAdmin', 'CmacAdmin', 'SupplierUser', 'LargeVehicleUser'],
      },
      {
        id: 3,
        description: 'Dispute guide',
        link: '/documents/SEP Disputes Guide V1.0.pdf',
        roles: ['SuperAdmin', 'CmacAdmin', 'SupplierUser', 'LargeVehicleUser'],
      },
      {
        id: 4,
        description: 'Cost confirmation guide',
        link: '/documents/SEP Cost Confirmation Guide V1.0.pdf',
        roles: ['SuperAdmin', 'CmacAdmin', 'SupplierUser', 'LargeVehicleUser'],
      },
    ]
  }
}
